import { useEffect, useMemo, useState } from "react";

import toast from "react-hot-toast";
import { useNavigate } from "react-router";

import {
  GetDashboardCompaniesDocument,
  GetSentimentFormRequestsDocument,
  GetUserHighlightsDocument,
} from "@/gql/graphql";

import { extractFormCategory } from "@/lib/utils.ts";

import { Toast, Icon } from "@/app/components";
import { Routes } from "@/app/constants";
import { useDynamicForm } from "@/app/hooks";
import { useGetSentimentQuery } from "@/app/hooks/use-get-sentiment-query";
import { normaliseDate } from "@/app/misc/helpers.ts";
import { useFillSentimentFormMutation } from "@/app/service/sentiment.gql.ts";
import { authStore } from "@/app/stores/auth.store.tsx";
import modalStore from "@/app/stores/modal.store";
import refetchStore from "@/app/stores/refetch-store";

import { CompleteSentimentActions } from "../components";
import { CompleteSentimentForm } from "../components/complete-sentiment-form";

export interface CompleteSentimentParams {
  id: string;
  label?: string;
  notificationId?: string;
}

export const useCompleteSentiment = ({
  id,
  label = "Complete Sentiment Form",
  notificationId = "",
}: CompleteSentimentParams) => {
  const navigate = useNavigate();
  const [formProgress, setFormProgress] = useState(0);

  const { sentimentForm, loading: gettingForm } = useGetSentimentQuery({
    id,
    userId: authStore.userId,
  });

  const { form } = useDynamicForm({
    ...sentimentForm,
    fields: [...(sentimentForm?.fields || []), { field_id: "concerns_short_text", type: "short_text" }],
  });

  useEffect(() => {
    const { formData, formProgress } = JSON.parse(localStorage.getItem(`form-${id}`) || "{}");

    if (formData) {
      form.reset(formData);
      setFormProgress(formProgress || 0);
    }
  }, [id, form]);

  useEffect(() => {
    localStorage.setItem(`form-${id}`, JSON.stringify({ formData: form.getValues(), formProgress }));
  }, [id, formProgress, form]);

  const [sentimentFormAction, { loading }] = useFillSentimentFormMutation();

  const onSubmit = async (values) => {
    const data = { ...values };

    const concernOtherIdx = (values.concerns_multiple_choice || []).findIndex((item) => item === "Other");

    if (concernOtherIdx >= 0) data["concerns_multiple_choice"][concernOtherIdx] = values["concerns_short_text"];

    const refetchQueries = refetchStore.getRefetchQueries([
      GetDashboardCompaniesDocument,
      GetUserHighlightsDocument,
      GetSentimentFormRequestsDocument,
    ]);

    await sentimentFormAction({
      variables: { input: { formId: id, answers: data } },
      refetchQueries,
      onCompleted: () => {
        modalStore.close();
        toast.custom((t) => (
          <Toast
            title="Success"
            visible={t.visible}
            icon={<Icon type={"FillCheck"} className="text-green-500" />}
            subTitle="Successfully completed sentiment"
            handleClose={() => toast.dismiss(t.id)}
          />
        ));
        const company_metrics = Boolean(form?.getValues?.("company_metrics"));
        if (company_metrics) {
          navigate(`${Routes.metricsExtraction}/new`, { state: { companyId: sentimentForm?.company?.id } });
        }
      },
    });
  };

  const sentimentFields = useMemo(() => {
    const serverFields = [
      ...(sentimentForm?.fields || [])
        .reduce(
          (entryMap, field) => entryMap.set(field.group_id, [...(entryMap.get(field.group_id) || []), field]),
          new Map(),
        )
        .values(),
    ];
    if (sentimentForm?.company) {
      const { company } = sentimentForm;
      serverFields.push([
        {
          id: company.id,
          company,
          flag: company.isOpCompany ? "6_month_runway" : "sharing_allowed",
          name: company.isOpCompany ? "Raising in 6 Months" : "Sharing Company",
          group_id: "toggle_flag",
          type: "toggle_flag",
          subTitle: company.isOpCompany
            ? "Do you think that the company will look to raise a round in the next 6 months?"
            : "Are you open to team members sharing this company with others in our network?",
          validations: {
            required: false,
          },
        },
      ]);
    }
    return serverFields;
  }, [sentimentForm]);

  const validateField = (field) => {
    if (!field || !field.validations?.required) return true;

    return form.trigger(field.field_id, { shouldFocus: true });
  };

  const handleSubmit = form.handleSubmit(onSubmit);

  const handleBack = () => setFormProgress((prevState) => (prevState - 1 >= 0 ? prevState - 1 : prevState));
  const handleNext = async () => {
    const validState = await validateField(sentimentFields[formProgress]?.[0]);

    if (validState && sentimentFields.length > formProgress + 1) {
      setFormProgress((prevState) => prevState + 1);
    }
  };

  return {
    title: label,
    subTitle: `${extractFormCategory(sentimentForm)} • ${
      sentimentForm?.companyName
    } • ${sentimentForm?.createdAt && normaliseDate(sentimentForm?.createdAt)}`,
    sentimentForm,
    renderForm: (
      <CompleteSentimentForm
        form={form}
        sentimentForm={sentimentForm}
        sentimentFields={sentimentFields}
        formProgress={formProgress}
        gettingForm={gettingForm}
        notificationId={notificationId}
      />
    ),
    actions: (
      <CompleteSentimentActions
        showBack={formProgress > 0}
        loading={loading}
        showNext={sentimentFields.length > formProgress + 1}
        disabled={!sentimentForm}
        onBack={handleBack}
        onNext={handleNext}
        onSubmit={handleSubmit}
      />
    ),
  };
};
