import React, { useState } from "react";

import { Link } from "react-router-dom";

import { EmailOpportunityAction } from "@/gql/graphql.ts";

import { useBreakpoints } from "@/lib/hooks";

import { Button, Icon, Modal, NativeModal } from "@/app/components";
import { Company } from "@/app/hooks";
import { NotificationStatus } from "@/app/screens/dashboard/dashboard.types.ts";
import { newItemStatuses } from "@/app/screens/dashboard/sections/company-updates/use-company-updates.ts";
import { SearchCompany } from "@/app/screens/modal/actions/add-sentiment/components";
import { EmailProps, EmailView } from "@/app/screens/modal/notifications/email.tsx";
import { useSetOpportunityEmailMutation } from "@/app/service/dashboard.gql.ts";
import modalStore from "@/app/stores/modal.store.tsx";

type CompanyUpdatesDetailsProps = {
  company?: {
    id: string;
    name: string;
  } | null;
  emailId: string;
  email?: EmailProps | null;
  status: NotificationStatus;
};

const CompanySelect = ({ emailId }: { emailId: string }) => {
  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);
  const [acceptOpportunityEmail, { loading }] = useSetOpportunityEmailMutation({
    refetchQueries: ["GetDashboardCompanies", "GetUserHighlights", "GetCompanyUpdates"],
  });

  const handleApply = () => {
    if (!selectedCompany || !emailId) {
      return;
    }

    acceptOpportunityEmail({
      variables: {
        input: {
          action: EmailOpportunityAction.Accept,
          emailOpportunityId: emailId,
          companyId: selectedCompany?.id,
        },
      },
    }).then(() => {
      modalStore.close();
    });
  };

  return (
    <div className="flex items-start gap-4">
      <SearchCompany
        companySearchType="lg"
        selectCompany={(comp) => setSelectedCompany(comp)}
        additionalSearchFilter={{
          isTrCompany: { equalTo: true },
        }}
        onReset={() => setSelectedCompany(null)}
      />
      <Button className="mt-3" onClick={handleApply} disabled={!selectedCompany || loading} loading={loading}>
        Apply
      </Button>
    </div>
  );
};

const CompanyUpdatesDetails = ({ company, email, emailId, status }: CompanyUpdatesDetailsProps) => {
  return (
    <div className="space-y-4">
      {company ? (
        <div className="flex items-center gap-2 rounded-sm bg-neutral-100 p-2">
          <Icon type={"Check"} className="size-10 text-green" />
          <span className="font-semibold">
            Successfully added updates to company{" "}
            <Link to={`/company/${company.id}`} target={"_blank"} className="underline hover:text-neutral-700">
              {company.name}
            </Link>
          </span>
        </div>
      ) : (
        newItemStatuses.includes(status) && <CompanySelect emailId={emailId} />
      )}
      {email != null && (
        <div>
          <h1 className="text-start text-3xl font-semibold">Email</h1>
          <div className="mt-2 rounded-sm bg-neutral-100 p-2 text-start text-sm">
            <EmailView email={email!} />
          </div>
        </div>
      )}
    </div>
  );
};

export const CompanyUpdatesDetailsModal = (props: CompanyUpdatesDetailsProps) => {
  const { isBigTablet } = useBreakpoints();

  return isBigTablet ? (
    <Modal isOpen showX={false} handleClose={modalStore.close} bodyContentClassName="p-0">
      <CompanyUpdatesDetails {...props} />
    </Modal>
  ) : (
    <NativeModal isOpen showX={false} handleClose={modalStore.close}>
      <CompanyUpdatesDetails {...props} />
    </NativeModal>
  );
};
