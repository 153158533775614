import { getOperationName } from "@apollo/client/utilities";
import { DocumentNode } from "graphql";
import { action, makeObservable, observable, runInAction } from "mobx";

type TVariables = { pageSize: number } & Record<string, unknown>;

class RefetchStore {
  $queries: Map<
    string,
    {
      query: DocumentNode;
      variables: TVariables;
      limit?: number;
    }
  > = new Map();

  constructor() {
    makeObservable<RefetchStore, "$queries">(this, {
      $queries: observable,
      register: action,
      updateLimit: action,
      getRefetchQueries: action,
    });
  }

  get queries() {
    return this.$queries;
  }

  register(query: DocumentNode, variables?: TVariables) {
    const key = getOperationName(query);
    if (key) this.$queries[key] = { query, variables };
  }

  updateLimit(query: DocumentNode, limit: number) {
    const key = getOperationName(query);

    if (key && this.$queries[key]) {
      const { variables, query } = this.$queries[key];

      runInAction(() => {
        this.$queries.set(key, { query, variables, limit: limit > variables.pageSize ? limit : variables.pageSize });
      });
    }
  }

  getRefetchQueries(queries: DocumentNode[]) {
    return queries.map((node) => {
      const key = getOperationName(node);

      if (!key) return key;

      const item = this.$queries.get(key);

      if (!item) return key;

      const { query, variables, limit } = item;

      return JSON.parse(
        JSON.stringify({
          query: { ...query },
          variables: { ...variables, pageSize: limit || variables.pageSize },
        }),
      );
    });
  }
}

export default new RefetchStore();
