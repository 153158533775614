import React, { useState } from "react";

import { observer } from "mobx-react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";

import { EmailOpportunityAction } from "@/gql/graphql.ts";

import { cn, windowOpen } from "@/lib/utils.ts";

import { Button, Icon, IconButton, Text } from "@/app/components";
import { BreadCrumbs } from "@/app/components/navbar";
import { Routes } from "@/app/constants";
import globalModalStore from "@/app/screens/modal/global-modal.store.ts";
import { useGetOpportunityEmailSuspenseQuery, useSetOpportunityEmailMutation } from "@/app/service/dashboard.gql.ts";

import { AddOpportunitiesViaEmail } from "../add-new/via-email/add-opportunities-via-email.tsx";

const CompanyUpdatesFooter = ({ selectedCompany, onSelect }) => (
  <div className={cn("flex w-full justify-between")}>
    <Button
      text={"Cancel"}
      variant={"outline"}
      className="mr-4 rounded-sm border-white text-black"
      onClick={() => globalModalStore.toggleModal()}
    />
    <Button
      text={"Select"}
      disabled={!selectedCompany?.name}
      variant={"gradient"}
      className="rounded-sm"
      onClick={() => {
        onSelect(selectedCompany);
        globalModalStore.toggleModal();
      }}
    />
  </div>
);

const AddCompanyUpdatesViaEmail = observer(({ data, navigateOnSuccessPath }) => {
  const navigate = useNavigate();
  const error = data?.nzrOpportunityEmail?.metadata?.process_update_email?.error;
  const { company_id, company_name, card_id } = data?.nzrOpportunityEmail?.metadata?.process_update_email?.data || {};
  const [selectedCompany, setSelectedCompany] = useState();
  const [acceptOpportunityEmail, { loading }] = useSetOpportunityEmailMutation();

  return (
    <div className="flex flex-col items-center">
      {!error && (
        <div className={"flex h-1/5 w-full cursor-pointer select-none items-center rounded-md p-4 hover:bg-neutral-50"}>
          <div className={"flex items-center justify-center"}>
            <Icon type={"Check"} className={"mr-2 size-10 text-green"} />
          </div>
          <div className={"flex flex-col"}>
            <Text text={`Successfully added updates to company ${company_name}`} type={"subtitle"} align={"justify"} />
          </div>
        </div>
      )}
      {error && (
        <div className="flex flex-row items-center">
          <Button
            variant="tertiary"
            className="rounded-sm italic text-black active:text-black"
            text={selectedCompany?.id ? `Selected company: ${selectedCompany.name}` : "No company is selected"}
            iconRight={"Trello"}
          />
          {selectedCompany?.id && (
            <IconButton
              icon={"Link"}
              onClick={() => {
                windowOpen(`${window.location.host}/company/${selectedCompany.id}`, "_blank");
              }}
              containerClassName={"h-4 w-4"}
            />
          )}
          <IconButton
            icon={"Pencil"}
            onClick={() => {
              globalModalStore.navigate({
                modalType: "CompanyLink",
                navState: {
                  companyType: "lg",
                  additionalSearchFilter: {
                    isTrCompany: { equalTo: true },
                  },
                  FooterComp: (props) => (
                    <CompanyUpdatesFooter {...props} onSelect={(company) => setSelectedCompany(company)} />
                  ),
                },
              });
            }}
            containerClassName={"h-4 w-4 ml-4"}
          />
        </div>
      )}
      <div className="mt-4 flex flex-row items-end justify-end">
        <div className="flex flex-row gap-2">
          {error && (
            <>
              <Button
                loading={loading}
                text={"Accept"}
                disabled={!selectedCompany?.id}
                variant={"primary"}
                onClick={() => {
                  acceptOpportunityEmail({
                    variables: {
                      input: {
                        action: EmailOpportunityAction.Accept,
                        emailOpportunityId: data?.nzrOpportunityEmail?.id,
                        companyId: selectedCompany?.id,
                      },
                    },
                    onCompleted: () => {
                      navigate(navigateOnSuccessPath ?? Routes.home);
                    },
                  });
                }}
              />
              <Button
                loading={loading}
                text={"Decline"}
                variant={"outline-black"}
                onClick={() => {
                  acceptOpportunityEmail({
                    variables: {
                      input: {
                        action: EmailOpportunityAction.Decline,
                        emailOpportunityId: data?.nzrOpportunityEmail?.id,
                      },
                    },
                    onCompleted: () => {
                      navigate(navigateOnSuccessPath ?? Routes.home);
                    },
                  });
                }}
              />
            </>
          )}
          {!error && (
            <>
              <Link to={`/company/${company_id}`} target={"_blank"}>
                <Button text={"Go to company"} variant={"primary"} />
              </Link>
              <Link to={`https://trello.com/c/${card_id}`} target={"_blank"}>
                <Button text={"Go to trello"} variant={"outline-black"} />
              </Link>
            </>
          )}
          <Button
            text={"Show original email"}
            variant={"black"}
            onClick={() => {
              globalModalStore.toggleSlideOver({
                isOpen: true,
                slideOverType: "OpportunityEmail",
                navState: { email: data.nzrOpportunityEmail },
              });
            }}
          />
        </div>
      </div>
    </div>
  );
});

export const EmailActionView = observer(({ navigationPath }: { navigationPath?: { url: string; label: string } }) => {
  const { id } = useParams();
  const { data } = useGetOpportunityEmailSuspenseQuery({
    variables: { id },
    skip: !id,
  });
  const navigate = useNavigate();
  const navigatePath = navigationPath?.url ?? Routes.home;

  const parserType = data.nzrOpportunityEmail?.parser;
  const error = data?.nzrOpportunityEmail?.metadata?.error;

  if (!id) {
    return;
  }
  return (
    <div className="flex size-full flex-col items-center overflow-auto bg-background p-8 px-[2%] pb-[82px] md:px-[5%] lg:px-[10%] lg:pb-2">
      <div id="header" className={"mb-10 flex w-full flex-row items-center justify-between"}>
        <div>
          <BreadCrumbs
            title={
              navigationPath?.label ??
              (parserType === "OPPORTUNITY_EMAIL" ? "Opportunities via email" : "Company Updates")
            }
            icon={"Chevron Left"}
            className={"mb-0"}
            action={() => navigate(navigatePath)}
          />
        </div>
        {error && (
          <div>
            <div
              className={"flex h-1/5 w-full cursor-pointer select-none items-center rounded-md p-4 hover:bg-neutral-50"}
            >
              <div className={"flex items-center justify-center"}>
                <Icon type={"Shield Alert"} className={"mr-2 size-10 text-red"} />
              </div>
              <div className={"flex flex-col"}>
                <Text text={"Email parsing failed."} type={"subtitle"} align={"justify"} />
                <Text
                  text={
                    parserType === "OPPORTUNITY_EMAIL"
                      ? "Please manually add required opportunities below."
                      : "Please select the required company to add updates to !"
                  }
                  type={"body"}
                  color={"text-neutral-600"}
                  italic
                  align={"justify"}
                />
              </div>
            </div>
          </div>
        )}
      </div>

      <div className={"mb-10 w-full"}>
        {parserType === "OPPORTUNITY_EMAIL" && <AddOpportunitiesViaEmail data={data} />}
        {parserType === "COMPANY_UPDATES" && (
          <AddCompanyUpdatesViaEmail data={data} navigateOnSuccessPath={navigatePath} />
        )}
      </div>
    </div>
  );
});
