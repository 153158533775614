import { useEffect, useMemo, useState } from "react";

import { QueryHookOptions, TypedDocumentNode, useQuery } from "@apollo/client";

import refetchStore from "../stores/refetch-store";

export type GetEntityQuery = { __typename?: string };

export type QueryVariables = { pageSize: number } & Record<string, unknown>;

type Result = {
  totalCount: number;
  pageInfo?: {
    endCursor?: string | null;
    startCursor?: string | null;
    hasNextPage?: boolean;
    hasPreviousPage?: boolean;
  };
  edges?: Array<{
    node: unknown;
  }>;
};

type PaginatedResult<T> = {
  [Property in keyof T]?: Result | "Query" | null;
};

interface UseQueryParams<T extends PaginatedResult<T>, TVariables extends QueryVariables, Root extends keyof T> {
  query: TypedDocumentNode<T, TVariables>;
  rootEntity: Root;
  variables: TVariables;
  options?: QueryHookOptions<T, TVariables>;
}

const parseData = (result: Result, variables: QueryVariables, total: number) => {
  const hasPreviousPage = result.pageInfo?.hasPreviousPage || total > variables.pageSize;

  return {
    ...result,
    pageInfo: {
      ...result.pageInfo,
      hasPreviousPage,
    },
  };
};

export const usePaginatedQuery = <
  T extends PaginatedResult<T>,
  TVariables extends QueryVariables,
  Root extends keyof T,
>({
  query,
  rootEntity,
  variables,
  options,
}: UseQueryParams<T, TVariables, Root>) => {
  refetchStore.register(query, variables);

  const { data: rawData, loading, refetch, fetchMore } = useQuery(query, { ...options, variables });
  const [loadingState, toggleLoading] = useState({
    lessBtn: false,
    moreBtn: false,
  });

  const loadMore = async (queryVariables, cursor?: string | null) => {
    if (!cursor) {
      return;
    }

    try {
      toggleLoading({ moreBtn: true, lessBtn: false });
      await fetchMore({
        variables: {
          ...queryVariables,
          after: cursor,
        },
      });
    } finally {
      toggleLoading({ moreBtn: false, lessBtn: false });
    }
  };

  const loadLess = async () => {
    try {
      toggleLoading({ moreBtn: false, lessBtn: true });
      await refetch();
    } finally {
      toggleLoading({ moreBtn: false, lessBtn: false });
    }
  };

  const { totalFetched, data } = useMemo(() => {
    if (rawData && rawData[rootEntity] && typeof rawData[rootEntity] !== "string" && rawData[rootEntity].edges) {
      const total = rawData[rootEntity].edges?.length;
      const parsedResult = parseData(rawData[rootEntity], variables, total);
      return {
        totalFetched: total,
        data: {
          ...rawData,
          [rootEntity]: parsedResult,
        } as T,
      };
    }

    return { totalFetched: 0, data: null };
  }, [rawData, variables, rootEntity]);

  useEffect(() => {
    refetchStore.updateLimit(query, totalFetched);
  }, [totalFetched, query]);

  return {
    loadingLess: loadingState.lessBtn,
    loadingMore: loadingState.moreBtn,
    loading,
    data,
    loadMore,
    loadLess,
  };
};
