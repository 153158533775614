import { useNavigate } from "react-router";

import { EmailOpportunityAction } from "@/gql/graphql.ts";

import { useSetOpportunityEmailMutation } from "@/app/service/dashboard.gql.ts";

import { SubmittedCompanyData } from "./add-opportunities-via-email.types.ts";
import { CompaniesFromEmailForm } from "./use-add-company-from-email-form.ts";

export const useSubmitCompaniesViaEmail = (emailId?: string) => {
  const navigate = useNavigate();
  const [acceptOpportunityEmail, { loading }] = useSetOpportunityEmailMutation();

  const onCompleted = () => {
    navigate("/home");
  };

  const submit = (companies: CompaniesFromEmailForm["companies"]) => {
    if (!emailId) {
      throw new Error("Unknown email id");
    }

    const parsed = companies
      .filter((company) => !company.skip)
      .map<SubmittedCompanyData>(({ company }) => {
        return {
          dealroomCompany: company.mainData.dealroomCompany?.id,
          lgCompany: company.mainData.lgCompany?.id,
          name: company.mainData.name,

          websiteUrl: company.details.websiteUrl,
          about: company.details.about,

          country: company.details.location.country,
          city: company.details.location.city,

          founders: company.founders.founders,

          industries: company.additionalDetails.industries,
          round: company.additionalDetails.round,
          targetFund: company.additionalDetails.fund,
          investmentType: company.additionalDetails.investmentType,

          note: company.note.note,

          source: company.attributes.source.name,
          sourceType: company.attributes.source.type,

          canIntro: company.attributes.attributes.introAvailable,
          highPriority: company.attributes.attributes.highPriority,
          isActivelyFundraising: company.attributes.attributes.activelyRaising,

          assignees: company.attributes.assignees.people.map((p) => p.id),
          noteToAssignees: company.attributes.assignees.note,
        };
      });

    acceptOpportunityEmail({
      variables: {
        input: {
          action: EmailOpportunityAction.Accept,
          emailOpportunityId: emailId,
          opportunities: parsed,
        },
      },
    }).then(onCompleted);
  };

  const decline = () => {
    if (!emailId) {
      throw new Error("Unknown email id");
    }

    acceptOpportunityEmail({
      variables: {
        input: {
          action: EmailOpportunityAction.Decline,
          emailOpportunityId: emailId,
        },
      },
    }).then(onCompleted);
  };

  return { accept: submit, decline, loading };
};
