import { GetSentimentFormRequestsDocument } from "@/gql/graphql";

import { usePaginatedQuery } from "@/app/hooks";
import { DashboardSectionData } from "@/app/screens/dashboard/dashboard.types.ts";
import { GetSentimentFormRequestsQuery } from "@/app/service/dashboard.gql.ts";
import { authStore } from "@/app/stores/auth.store.tsx";

export type SentimentFormData = NonNullable<
  GetSentimentFormRequestsQuery["nzrSentimentForms"]
>["edges"][number]["node"];

export const useSentimentFormRequests = (showArchive: boolean): DashboardSectionData<SentimentFormData> => {
  const queryVariables = {
    assignee: authStore.user?.id,
    pageSize: 3,
    completed: showArchive,
  };

  const {
    data,
    loading,
    loadingLess,
    loadingMore,
    loadLess,
    loadMore: fetchMore,
  } = usePaginatedQuery({
    query: GetSentimentFormRequestsDocument,
    variables: queryVariables,
    rootEntity: "nzrSentimentForms",
    options: { fetchPolicy: "cache-and-network" },
  });

  const loadMore = () => {
    const endCursor = data?.nzrSentimentForms?.pageInfo.endCursor;

    if (fetchMore) {
      fetchMore(queryVariables, endCursor);
    }
  };

  if (!data?.nzrSentimentForms) {
    return {
      totalCount: 0,
      items: [],
      loading,
      loadingLess: false,
      loadingMore: false,
      pageInfo: null,
    };
  }

  return {
    totalCount: data.nzrSentimentForms.totalCount,
    items: data.nzrSentimentForms.edges.map(({ node }) => node),
    pageInfo: data.nzrSentimentForms.pageInfo,
    loading: loading && !loadingLess,
    loadingLess,
    loadingMore,
    loadLess,
    loadMore,
  };
};
