import { useCallback, useMemo } from "react";

import { UserTag, Action } from "@/gql/graphql";

import { ButtonColorType } from "../components/button";
import { getCompanyUserTags } from "../misc/parse-company-helpers";
import {
  AssignModalResponsive,
  MoveInTrelloModal,
  AddNewResponsiveModal,
  SnoozeResponsiveModal,
  ConfirmModal,
} from "../screens/modal";
import { ConvertToPortfolio } from "../screens/modal/actions/convert-to-portfolio";
import { SnoozeActionType } from "../screens/modal/actions/snooze/hooks";
import { authStore } from "../stores/auth.store";
import modalStore from "../stores/modal.store";
import { Company } from "../types";

import { useActionMutation } from "./use-action-mutation";
import { useTagMutation } from "./use-tag-mutation";
import { useUnAssignUser } from "./use-un-assign-user-action";

export type ActionNames =
  | "hide"
  | "follow"
  | "share"
  | "removeProspect"
  | "convertToPortfolio"
  | "convertToProspect"
  | "addSentiment"
  | "moveToPipeline"
  | "moveStage"
  | "unAssign";

export type OpportunityAction = {
  label: string;
  action: () => void;
  variant?: ButtonColorType;
  disabled?: boolean;
  loading?: boolean;
};

export interface OpportunityActionsHookParams {
  company?: Partial<Company> | null;
  refresh?: () => void;
}

export const useOpportunityActions = ({ company, refresh }: OpportunityActionsHookParams) => {
  const userTags = getCompanyUserTags(company || {});

  const { onSubmit } = useActionMutation({});
  const { onSubmit: unAssign } = useUnAssignUser();
  const { onSubmit: submitTag } = useTagMutation({});

  const handleActionSuccess = useCallback(() => {
    if (refresh) refresh();
    modalStore.close();
  }, [refresh]);

  return useMemo(() => {
    if (!company) {
      return;
    }

    const isFollowing = userTags[authStore.userId]?.following;
    const isHidden = userTags[authStore.userId]?.hidden;

    const actions: Record<ActionNames, OpportunityAction> = {
      follow: {
        label: isFollowing ? "Unfollow" : "Follow",
        action: () =>
          submitTag({
            companyId: company.id,
            userId: authStore.userId,
            tag: UserTag.Follow,
            value: isFollowing ? 0 : 1,
            successMessage: isFollowing
              ? `You are no longer following ${company.name}`
              : `You are now following ${company.name}`,
          }),
        variant: "tertiary",
      },

      hide: {
        label: isHidden ? "Unhide" : "Hide",
        action: () =>
          submitTag({
            companyId: company.id,
            userId: authStore.userId,
            tag: UserTag.Hide,
            value: isHidden ? 0 : 1,
            successMessage: isHidden
              ? `${company.name} is no longer hidden from you`
              : `You will no longer see ${company.name} on your dashboard`,
          }),
        variant: "tertiary",
      },

      share: {
        label: "Assign for Review",
        action: () => {
          modalStore.open(AssignModalResponsive, {
            props: {
              id: company.id,
              title: "Assign for Review",
              action: Action.Reassign,
              onSuccess: handleActionSuccess,
            },
          });
        },
      },

      convertToProspect: {
        label: "Convert to Prospect",
        action: () => {
          modalStore.open(MoveInTrelloModal, {
            props: { id: company.id, title: "Convert Company to Prospect", onSuccess: handleActionSuccess },
          });
        },
      },

      addSentiment: {
        label: "Add Sentiment",
        action: () => {
          modalStore.open(AddNewResponsiveModal, {
            props: { company, sentimentOnly: true },
          });
        },
        variant: "primary",
      },

      moveToPipeline: {
        label: "Move Back to Pipeline",
        action: () => {
          modalStore.open(SnoozeResponsiveModal, {
            props: {
              id: company.id,
              title: "Move Back to Pipeline",
              actionType: SnoozeActionType.Pipeline,
              onSuccess: handleActionSuccess,
            },
          });
        },
      },

      moveStage: {
        label: "Move in Prospects",
        action: () => {
          modalStore.open(MoveInTrelloModal, {
            props: { id: company.id, title: "Move in Prospects", onSuccess: handleActionSuccess },
          });
        },
      },

      removeProspect: {
        label: "Move Back to Portfolio",
        action: () => {
          modalStore.open(ConfirmModal, {
            props: {
              title: "Are you sure you want to move this company back to portfolio view?",
              subTitle: "By confirming you will be removing this company from prospects list",
              handleConfirm: () =>
                onSubmit({
                  action: Action.Snooze,
                  entityId: company.id,
                  resurface: "never",
                  successMessage: "Moved back to Portfolio",
                }).then(handleActionSuccess),
            },
          });
        },
      },

      convertToPortfolio: {
        label: "Convert to Portfolio",
        action: () => {
          modalStore.open(ConvertToPortfolio, {
            props: {
              id: company.id,
              onSuccess: handleActionSuccess,
            },
          });
        },
      },

      unAssign: {
        label: "Not Relevant",
        variant: "tertiary",
        action: () =>
          modalStore.open(ConfirmModal, {
            props: {
              title: "Are you sure this Company is not relevant to you?",
              subTitle: "By confirming, you will be unassigned from the company",
              handleConfirm: () =>
                unAssign({
                  companyId: company.id,
                  userId: authStore.userId,
                }),
            },
          }),
      },
    };

    return actions;
  }, [company, userTags, unAssign, submitTag, handleActionSuccess, onSubmit]);
};
