import toast from "react-hot-toast";

import { GetDashboardCompaniesDocument, GetUserHighlightsDocument, UnAssignPayloadInput } from "@/gql/graphql";

import { Toast, Icon } from "@/app/components";
import {
  GetCompaniesDocument,
  GetCompanyDocument,
  useUnAssignUserActionMutation,
} from "@/app/service/opportunities.gql.ts";

import refetchStore from "../stores/refetch-store";

export const useUnAssignUser = () => {
  const [onSubmit, { loading }] = useUnAssignUserActionMutation({
    refetchQueries: ["GetCompany", "GetCompanies", "GetDashboardCompanies", "GetUserHighlights"],
    fetchPolicy: "network-only",
    onCompleted() {
      toast.custom((t) => (
        <Toast
          title="Success!"
          visible={t.visible}
          icon={<Icon type={"FillCheck"} className="text-green-500" />}
          subTitle={"Successfully UnAssigned from company"}
          handleClose={() => toast.dismiss(t.id)}
        />
      ));
    },
  });

  const handleSubmit = (input: UnAssignPayloadInput) => {
    const refetchQueries = refetchStore.getRefetchQueries([
      GetCompanyDocument,
      GetCompaniesDocument,
      GetDashboardCompaniesDocument,
      GetUserHighlightsDocument,
    ]);

    onSubmit({ variables: { input }, refetchQueries });
  };

  return {
    onSubmit: handleSubmit,
    loading,
  };
};
